
.paym_split2_super_container{
    padding: 10px;
}
.paym_split2_super_container_blur{
    -webkit-filter: blur(10px);
    filter: blur(10px);
}
.paym_split2_header_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.paym_split2_header_title{
    font-size: 22px;
    font-weight: 700;
    margin: 0;
}
.paym_split2_desc_text{
    margin-top: 2px;
}
.paym_split2_add_mem_btn{
    background-color: #B20C02;
    color: #FFFFFF;
    height: 48px;
    border: none;
    border-radius: 10px;
    width: 200px;
}
.paym_split2_header{
    display: flex;
    flex: 1 1 100%;
    padding: 5px 0;
    gap: 5px;
}
.paym_split2_header .paym_split2_text{
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    width: 100%;
}
.paym_split2_hr{
    height: 2px;
    background-color: #FCFCFD;
    border: none;
}
.paym_split2_section{
    display: flex;
    flex: 1 1 100%;
    padding: 5px 0;
    gap: 5px;
}
.paym_split2_section:hover{
    background-color: #FCFCFD;
}
.paym_split2_section .paym_split2_text_ln{
    width: 100%;
    font-size: 16px;
    width: 100%;
}
.paym_split2_action_div{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.paym_split2_action_div .delete_button{
    border: none;
    background-color: transparent;
    color: #EB5757;
}
.paym_split2_action_div .edit_button{
    border: none;
    background-color: transparent;
    color: #2F80ED;
}
.paym_split2_section .paym_split2_status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.paym_split2_section .paym_split2_status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
}
.paym_split2_add_customer_container{
    position: absolute;
    top: 300px;
    left: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.paym_split2_add_member_cont{
    position: absolute;
    top: 300px;
    left: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
