.login{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.hero_img{
    width: 100%;
    height: 100%;
}
.column1_signin{
    /* margin: 40px; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper1{
    width: 100%;
}
.column2_signin{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.column1_signin .login_form{
    width: 100%;
}
.column1_signin .login_credentials{
    background-color: rgb(250, 250, 250);
    padding: 20px;
    border-radius: 10px;
}
input{
    height: 48px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 8px;
    border: none;
    width: 100%;
    font-size: 1em;
}
input:focus{
    scale: 1.01;
    transition: 0.6s;
    outline: 1px solid #B20C02;

}   
.name_field{
    display: flex;
    gap: 30px
}
.button1{
    background-color: #B20C02;
    height: 48px;
    width: 100%;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 20px;
}
.forgot_password_link{
    font-size: 1.1em;
    font-weight: 500;
    color: #1849D6;
    text-align: center;
    display: block;
    margin-bottom: 50px;
    text-decoration: none;
}
.button2{
    border-color: #B20C02;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    height: 48px;
    border-radius: 8px;
    font-size: 1.1em;
    color: #B20C02;
    width: 100%;
    align-items: center;
}
.create_account_title{
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
}