.product_section{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
}
.product_info_div{
    display: flex;
    gap: 20px;
}
.product_info_ln1{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
}
.product_txt{
    margin: 5px;
    font-size: 14px;

}
.product_price{
    color: #27AE60;
    font-weight: 700;
    font-size: 18px;
}
.product_info_div img{
    background-color: #FAFAFA;
    max-width: 250px;
}
.product_action_div{
    margin-left: 220px;
    display: flex;
    gap: 20px;
}
.product_del_btn{
    background-color: transparent;
    border: none;
    color: lightcoral;
}
.product_edit_btn{
    background-color: transparent;
    border: none;
    color: lightblue;
}