
.sub_super_container{
    padding: 0px 20px 20px 20px;
}
.sub_super_container_blur{
    -webkit-filter: blur(10px);
    filter: blur(10px);
}
.sub_header_info{
}
.sub_header_title_wrpr{
    display: flex;
    justify-content: space-between;
    align-content: center;
    height: 60px;
}
.sub_header_title_wrpr2{
    display: flex;
    gap: 20px;
    align-items: center;
}
.sub_header_title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
}
.sub_header_title2{
    font-size: 16px;
    font-weight: 600;
    margin: 0; 
}
.sub_rev_wrpr{
    display: flex;
    gap: 50px;
}
.sub_edit_btn{
    background-color: #2F80ED;
    color: #FFFFFF;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: none;
    border-radius: 10px;
    width: 120px;
    font-size: 14px;
    font-weight: 500;
}
.sub_desc_text{
    margin-top: 2px;
    margin-bottom: 30px;
}

.sub_search_bar_container{
    width: 50%;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}
.sub_search_box{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 70%;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
    box-shadow: 0 0 5px #D0D5DD;
    border: 2px solid #D0D5DD;
    padding-left: 10px;

}

#sub_search_input{
    border: none;
    height: 100%;
    margin: 0;
    outline: none;
    scale: none;
    color: #667085;
    font-size: 16px;
}
.sub_search_filter{
    height: 53px;
    border-radius: 10px;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    box-shadow: 0 0 4px #D0D5DD;
    border: 2px solid #D0D5DD;
    color: #667085;
}
.sub_header{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.sub_header .sub_text{
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    width: 100%;
}
.sub_hr{
    height: 2px;
    background-color: #FCFCFD;
    border: none;
}
.sub_section{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.sub_section .sub_text_ln{
    width: 100%;
    font-size: 16px;
}
.sub_section .sub_status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.sub_section .sub_status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
}
/* .sub_section .sub_add_plan_container{
    position: absolute;
    top: 300px;
    left: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

} */