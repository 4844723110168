.cProduct_super_container{
    
}
.cProduct_header_info{
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 0 10px 0 10px;
}
.cProduct_header_title{
    font-size: 18px;
    font-weight: 600;
}
.cProduct_hr{
    height: 1px;
    background-color: #D9D9D9;
    border: none;
}
.cProduct_wrapper{
    padding: 30px 100px;
}
.cProduct_form{
    padding: 20px;
    border-radius: 10px;
    background-color: #CBE0FD;
    width: 600px;
}
.cProduct_i_prod_name{

}
.cProduct_i_prod_desc{
    height: 100px;
}
.cProduct_i_fieldset{
    display: flex;
    gap: 20px;
}
.cProduct_i_prod_price{
    width: 100%;
}
.cProduct_i_prod_avail{
    width: 100%;
}