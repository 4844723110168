
.t_super_container{
    padding: 20px;
}
.t_header_title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
}
.t_desc_text{
    margin-top: 2px;
    margin-bottom: 30px;
}
.t_row2_container{
    display: flex;
    justify-content: space-between;
}
.t_exp_btns_wrapper{
    display: flex;
    gap: 10px;
}
.t_exp_btn{
    height: 48px;
    border-radius: 8px;
    width: 120px;
    color: #B20C02;
    border: 2px solid #B20C02;
    background-color: transparent;
    font-size: 16px;
}
.t_search_bar_container{
    width: 50%;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}
.t_search_box{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 70%;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
    box-shadow: 0 0 5px #D0D5DD;
    border: 2px solid #D0D5DD;
    padding-left: 10px;

}

#t_search_input{
    border: none;
    height: 100%;
    margin: 0;
    outline: none;
    scale: none;
    color: #667085;
    font-size: 16px;
}
.t_search_filter{
    height: 53px;
    border-radius: 10px;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    box-shadow: 0 0 4px #D0D5DD;
    border: 2px solid #D0D5DD;
    color: #667085;
}
.t_header{
    display: flex;
    flex: 1 1 100%;
    background-color: #FCFCFD;
}
.t_header .t_text{
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    width: 100%;
}
.translog_hr{
    height: 1px;
    background-color: #D9D9D9;
    border: none;
}