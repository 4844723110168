
.translog_section{
    display: flex;
    flex: 1 1 100%;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
}
.translog_section .text_ln{
    font-size: 16px;
    width: 100%;
    font-size: 16px;
}
.translog_section .status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.translog_section .status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.translog_hr{
    height: 1px;
    background-color: #D9D9D9;
    border: none;
}