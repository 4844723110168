.new_plan_modal_wrapper{
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 10px gray;
    width: 500px;
    position: absolute;
    top: 300px;
    left: 40%;
}
.new_plan_title_div{
    background-color: #B20C02;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
}
.new_plan_title{
    margin: 0;
    color: #FFFFFF;
}
.new_plan_modal_close_btn{
    background-color: transparent;
    color: #FFFFFF;
    border: none;
}
.new_plan_form{
    padding: 20px;

}
.new_plan_form_label{

}
.new_plan_form_input{
    border: 1px solid #BCBCBC;
    padding: 2px 5px 2px 5px;
}
.new_plan_phone_div{
    display: flex;
    gap: 20px;
}
.new_plan_modal_btns_div{
    display: flex;
    justify-content: space-between;
}
.new_plan_modal_cancel_btn{
    background-color: transparent;
    height: 38px;
    padding: 10px;
    border-radius: 8px;
    border: 2px solid #D9D9D9;
    
}
.new_plan_modal_submit_btn{
    background-color: transparent;
    height: 38px;
    padding: 10px;
    border-radius: 8px;
    border: none;
    background-color: #B20C02;
    color: #FFFFFF;
}