.disp_super_container{
    padding: 20px;
}
.disp_super_wrapper{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.disp_header_info{
}
.disp_header_title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
}
.disp_desc_text{
    margin-top: 2px;
    margin-bottom: 30px;
}
.disp_search_bar_container{
    width: 50%;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}
.disp_search_box{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 70%;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
    box-shadow: 0 0 5px #D0D5DD;
    border: 2px solid #D0D5DD;
    padding-left: 10px;

}

#disp_search_input{
    border: none;
    height: 100%;
    margin: 0;
    outline: none;
    scale: none;
    color: #667085;
    font-size: 16px;
}
.disp_search_filter{
    height: 53px;
    border-radius: 10px;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    box-shadow: 0 0 4px #D0D5DD;
    border: 2px solid #D0D5DD;
    color: #667085;
}
.disp_container{
    width: 100%;
}
.disp_header{
    display: flex;
    flex: 1 1 100%;
    padding: 0 5px 0 10px;
    gap: 5px;
    background-color: #FCFCFD;
}
.disp_header .disp_text{
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    width: 100%;
}
.disp_hr{
    height: 1px;
    background-color: #D9D9D9;
    border: none;
}
.disp_section{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.disp_section:hover{
    background-color: #D9D9D9;
    scale: 1.005;
    transition: 1s;
}
.disp_section .disp_text_ln{
    width: 100%;
    font-size: 16px;
}
.disp_section .disp_status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.disp_section .disp_status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
}
.disp_info_container{
    width: 30%;
    margin-top: 8px;
    padding: 0 10px 0 10px;
}
.disp_info_title{
    font-size: 16px;
    font-weight: 600;
}
.disp_amount{
    font-size: 16px;
}
.disp_btn_container{
    display: flex;
    justify-content: center;
    background-color: #ECF1F4;
    height: 30px;
    border-radius: 10px;
    width: 200px;
}
.res_dis_btn{ 
    height: 100%;
    border: none;
    width: 100%;
    margin: 0 10px 0 10px;
    background-color: transparent;
}
.disp_charge_back_message, .disp_dispute_message{

}
.disp_charge_back_amt{
    color: #EB5757;
    font-size: 16px;
    font-weight: 600;
}
.disp_accept_btn{
    height: 30px;
    width: 200px;
    background-color: #02B387;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
}
.disp_decline_btn{
    height: 30px;
    width: 200px;
    background-color: #B20C02;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;  
}
.disp_decline_text_input{
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    height: 80px;
    padding: 10px;
}
.disp_upload_btn{
    width: 200px ;
    height: 30px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    margin-bottom: 30px;
    display: block;
}