.aud_log_view_screen{
    padding: 20px;
}
.aud_log_super_wrapper{
    display: flex;
    flex-direction: row;
}
.aud_log_header_info{
}
.aud_log_header_title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
}
.aud_log_desc_text{
    margin-top: 2px;
    margin-bottom: 30px;
}
.aud_log_container{
    width: 100%;
}
.aud_log_header{
    display: flex;
    flex: 1 1 100%;
    padding: 0 5px 0 10px;
    gap: 5px;
}
.aud_log_header .aud_log_text{
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    width: 100%;
}
.aud_log_hr{
    height: 1px;
    background-color: #D9D9D9;
    border: none;
}
.aud_log_section{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.aud_log_section:hover{
    background-color: #D9D9D9;
    scale: 1.005;
    transition: 1s;
}
.aud_log_section .aud_log_text_ln{
    width: 100%;
    font-size: 16px;
}
.aud_log_section .aud_log_status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.aud_log_section .aud_log_status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
}
.aud_log_info_container{
    width: 30%;
    border: 1px solid #D9D9D9;
    margin-top: 8px;
    padding: 10px;
}
.aud_log_user_cont{
    display: flex;
    gap: 10px;
}
.aud_log_info_div{
    display: flex;
    gap: 10px;
}
.aud_log_wrap{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.aud_log_info_header{
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.aud_log_header2{
    display: flex;
    padding: 0 10px 0 10px;
}
.aud_log_header2 .aud_log_text:nth-child(2){
    text-align: right;
}
.aud_log_section2 .aud_log_text_ln:nth-child(2){
    text-align: right;
}
.aud_log_info_txt{
    margin: 0;
    padding: 0;
}
.aud_log_info_title{
    font-size: 16px;
    font-weight: 600;
    padding: 0 10px 0 10px;
}
.aud_log_header{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
}
.aud_log_text{
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    font-size: 14px;
}
.aud_log_text_ln{
    width: 100%;
    color: #4A4A68;
    font-size: 14px;
}