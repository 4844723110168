.profile{
    display: flex;
    flex-direction: column;
}
.profile_header_info{
}
.profile_header_title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
}
.profile_desc_text{
    margin-top: 2px;
    margin-bottom: 30px;
    border-bottom: 1px solid gray;
}
/* .profile .profile_hr{
    height: 2px;
    background-color: green;
} */
.profile_doc_container{
    display: flex;
    gap: 20px;
}
.profile_aside{
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    margin: 0;
    list-style: none;
}
.profile_aside li{
    padding: 10px; 
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}
.profile .profile_check_icon{
    position: absolute;
    right: 0;

}
.profile_aside .active{
    padding: 10px;
    border-bottom: 2px solid #B20C02;
}
.profile_info_part{
   
}
.profile_part{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.profile_part1, .profile_part4, .profile_part5{
    background-color: #FAFAFA;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
}
.profile_part3 .profile_bdetails_wrapper{
    display: flex;
    gap: 10px;
}
.profile_part_title{
    font-weight: 700;
    font-size: 18px;
}
.profile_pinfo_sec{

}
.profile_info_txt_bold{
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}
.profile_info_txt{
    font-size: 16px;
    color: #797979;
    margin-top: 0;
}

.verified{
    color: #20BF55;
}
.profile .upload_btn{
    width: 100%;
    height: 48px;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.profile .submit_btn{
      width: 100%;
    height: 48px;
    background-color: #B20C02;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;
}


/* 
.hero_img_signup{
    width: auto;
    height: 100%;
}
.column1{
    margin: 40px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.wrapper1{
    width: 70%;
}
.column2{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.column1 .login_form{
    width: 100%;
}
.title{
    margin-bottom: 0;
}
.title_parag{
    font-size: .8em;
    color: #8C8CA1;
    margin-top: 0;
    margin-bottom: 30px;
}
.column1 .create_account_data, 
.phone_email_data, 
.otp_data{
    background-color: rgb(250, 250, 250);
    padding: 40px;
    border-radius: 10px;
}
.otp_data{
    display: flex;
    flex-direction: column;
    gap: 10px
}
input{
    height: 48px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 8px;
    margin: 5px 0px 20px 0px;
    width: 100%;
    font-size: 1em;
}
input:focus{
    transition: 0.6s;
    outline: 1px solid #B20C02;
}   
.name_field{
    display: flex;
    gap: 30px
}
.button1{
    background-color: #B20C02;
    height: 48px;
    width: 100%;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 20px;
}
.getCodeBtn{
    width: 80px;
    height: 38px;
    background-color: #B20C02;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    float: right;
}
.resendCodeBtn{
    width: 130px;
    height: 38px;
    background-color: #FFDFDD;
    border: 1.5px solid #B20C02;
    border-radius: 8px;
    color: #B20C02;
    font-size: .7em;
    float: right;
}
.signup_button .signup_link{
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.forgot_password_link{
    font-size: 1.1em;
    font-weight: 500;
    color: #1849D6;
    text-align: center;
    display: block;
    margin-bottom: 50px;
    text-decoration: none;
}
.button2{
    border-color: #B20C02;
    border-style: solid;
    border-width: 2px;
    height: 48px;
    border-radius: 8px;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.create_account_title{
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
}
.create_account_link{
    text-decoration: none;
    color: #B20C02;
    width: 100%;
    text-align: center;
} */