.bus_ver_super_contaner{
    margin: 80px 20% 80px 20%;
    /* border-radius: 20px;
    padding: 40px;
    box-shadow: 0 0 10px gray;
    border: 1px solid #B20C02; */
}
.bus_ver_hd_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.bus_ver_hd_title{
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 500;
}
.bus_ver_hd_txt{
    margin-top: 0;
    font-size: 14px;
    font-weight: 500;
    color: #8C8CA1;
}
.bus_ver_visit_branch_btn{
    padding: 10px;
    height: 48px;
    border: none;
    border-radius: 10px;
    background-color: #B20C02;
    color: #FFFFFF;
}
.bus_ver_aside_tab{
    background-color: #ECF1F4;
    width: 150px;
    height: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
}
.bus_ver_aside_tab li{
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}
.bus_ver_aside_tab .active{
    border-bottom: 2px solid #B20C02;
}
.bus_ver_main{
    display: flex;
    gap: 100px;
}
.bus_ver_content_container{
}
.bus_ver_action_button{
    background-color: #B20C02;
    width: 100%;
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
    height: 48px;
}
.bus_ver_sec_title{
    font-size: 16px;
    font-weight: 600;
}
.bus_ver_img_sec{
    display: flex;
    flex-direction: column;
}
.bus_ver_img_sec img{
    width: 100px;
    margin-left: 25px;
}
.bus_ver_img_logo{
    width: 100px;
    height: 100px;
    border: 3px solid rgb(217, 212, 212);
    border-radius: 50%;
}
.bus_ver_img_span_tag{
    font-size: 11px;
    font-style: italic;
    color:#262628;
}
.bus_ver_img_txt{
    font-size: 12px;
    font-weight: 400;
    margin-left: 20px;
}
.bus_ver_upload_img_btn{
    border: 1px solid #D9D9D9;
    padding: 5px;
    width: 150px;
    border-radius: 5px;
    display: flex;
    gap: 10px;
    justify-content: center;   
}
.content_upload_frm{
    background-color: #ECF1F4;
    width: 400px;
    padding: 20px;
    margin: 10px 0 10px 0px;
    border-radius: 10px;
}
.content_title{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}
.bus_ver_upload_btn{
    border: 1px solid #D9D9D9;
    background-color: #e9e9ed;
    padding: 10px 10px;
    font-size: 12px;
    width: 100%;
    max-width: 100%; 
    border-radius: 10px;
    display: flex;
    gap: 10px;
    justify-content: center; 
    box-sizing: border-box;
}
.bus_ver_upload_btn:hover{
    opacity: 70%;
    background-color: #c4c1c1;
}
.content_input{
    margin-bottom: 10px;
    width: 100%;
    height: 48px;
}

.content_select{
    margin-bottom: 10px;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
}
.content_select_input{
    border: 1px solid #D9D9D9;
    margin-bottom: 10px;
    width: 100%;
    height: 48px;
    border-radius: 10px;
}
