.signup{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.signup .warning{
    color: #B20C02;
    text-align: center;
}
.hero_img_signup{
    width: auto;
    height: 100%;
}
.column1{
    /* margin: 40px; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.wrapper1{
    width: 70%;
}
.column2{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.column1 .signup_form{
    width: 100%;
}
.title{
    margin-bottom: 0;
}
.title_parag{
    font-size: .8em;
    color: #8C8CA1;
    margin-top: 0;
    margin-bottom: 30px;
}
.column1 .create_account_data, 
.phone_email_data, 
.otp_data{
    background-color: rgb(250, 250, 250);
    padding: 40px;
    border-radius: 10px;
}
.otp_data{
    display: flex;
    flex-direction: column;
    gap: 10px
}
input{
    height: 48px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 8px;
    margin: 5px 0px 20px 0px;
    width: 100%;
    font-size: 1em;
}
input:focus{
    transition: 0.6s;
    outline: 1px solid #B20C02;
}   
.name_field{
    display: flex;
    gap: 30px
}
.button1{
    background-color: #B20C02;
    height: 48px;
    width: 100%;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 20px;
}
.getCodeBtn{
    width: 80px;
    height: 38px;
    background-color: #B20C02;
    border-radius: 8px;
    border: none;
    color: #FFFFFF;
    float: right;
}
.resendCodeBtn{
    width: 130px;
    height: 38px;
    background-color: #FFDFDD;
    border: 1.5px solid #B20C02;
    border-radius: 8px;
    color: #B20C02;
    font-size: .7em;
    float: right;
}
.signup_button .signup_link{
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.forgot_password_link{
    font-size: 1.1em;
    font-weight: 500;
    color: #1849D6;
    text-align: center;
    display: block;
    margin-bottom: 50px;
    text-decoration: none;
}
.button2{
    border-color: #B20C02;
    border-style: solid;
    border-width: 2px;
    height: 48px;
    border-radius: 8px;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.create_account_title{
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
}
.create_account_link{
    text-decoration: none;
    color: #B20C02;
    width: 100%;
    text-align: center;
}