.trans_sec{
    display: flex;
    flex: 1 1 100%;
    margin: 0;
    padding: 10px;
}
.gray{
    background-color: #F5F5F5;
}
.trans_txt{
    width: 100%;
    font-size: 16px;
    font-weight: 40;
    width: 100%;
    margin: 0;
}
.successful{
    color: #27AE60;
}
.failed{
    color: #EB5757;
}
.table_title{
    background-color: #1D4ED8;
    margin: 0;
    padding: 5px;
    text-align: center;
    width: 60px;
    border-radius: 5px;
    color: #FFFFFF;
}