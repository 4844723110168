.warning_modal_container_hide{
    display: none;
}
.warning_modal_container{
    width: 300px;
    height: 300px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px gray;
    background-color: #FFFFFF;


}
.warning_modal_wrpr{
    background-color: rgb(244, 244, 244);
    padding: 10px;
    border-radius: 50%;
    margin-bottom: 20px;

}
.warning_modal_text{
    font-size: 17px;
    font-weight: 700;
    margin: 0;
    text-align: center;
}
.warning_modal_text2{
    font-size: 17px;
    margin: 0;
    text-align: center;
}
.warning_modal_ok_btn{
    background-color: #B20C02;
    width: 100%;
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
    height: 48px;
    margin-top: 20px;
    border: none;

}