.landing{
    
}
.landing .header_container{
    background-image: url("../assets/hero.png");
    background-size: cover;
    max-width: 100%;
    height: 600px;
    padding: 40px;
    position: relative;
}
.landing .header_tab{
   background-color: #FFFFFF;
   border-radius: 15px;
   display: flex;
   align-items: center;
   padding: 10px;
}
.landing .header_logo{  

}
.landing .row1{
    
}
.landing .ibills_logo{

}
.landing .header_links{
    margin-left: 7%;
    list-style-type: none;
    display: flex;
    gap: 30px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
}
.landing .header_button_div{
    position: absolute;
    display: flex;
    gap: 20px;
    right: 70px;
    
}
.landing .signin_btn{
    font-weight: 600;
    font-size: 16px;
    color: #B20C02;
    border: none;
    background-color: transparent;
}
.landing .login_btn{
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    padding: 5px;
    width: 80px;
    border-radius: 10px;
    border: none;
    background-color: #B20C02;
}
.landing .text_banner_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.landing .banner_title{
    margin-top: 100px;
}
.landing .banner_title h1{
    margin: 0;
    font-size: 70px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
}
.landing .banner_message{
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 50px ;
    margin-bottom: 50px;
    text-shadow: 0 0 5px rgba(0,0,0,1);
}
.landing .get_started_button{
    width: 180px;
    padding: 10px;
    border-radius: 10px;
    color: #B20C02;
    font-weight: 600;
    font-size: 16px;
    border: none;
}
.landing .main{
    display: flex;
    flex-direction: column;
}
.landing .main_row1{
    background-color: #FFFAFA;
    max-width: 100%;
    padding: 100px;
}
.landing .row1_banner_text_container{
    text-align: center;
}
.landing .row1_banner_text_title{
    font-size: 38px;
    font-weight: 700;
    margin-top: 0;
}   
.landing .row1_banner_text_msg{
    font-size: 22px;
    font-weight: 500;
}
.landing .row1_section_container{
    display: flex;
    gap: 20px;
    justify-content: center;
}
.landing .row1_section{
    padding: 20px;
    border-radius: 10px;
    background-color: #FFFFFF;
    height: 250px;
    width: 20%;
}
.landing .row1_section:hover{
    background-color: #B20C02;
    color: #FFFFFF;
}
.landing .row1_section_icon_wrapper{
    display: flex;
    width: 30px;
    justify-content: center;
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 8px;
}
.landing .row1_section_title{
    font-size: 22px;
    font-weight: 700;
}
.landing .row1_section_text{
    font-size: 18px;
    font-weight: 400;
}
.landing .main_row2{
    max-width: 100%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 100px;
}
.landing .row2_section{
   width: 30%;
}
.landing .row2_section_title{
    font-size: 36px;
    font-weight: 700;
    margin-top: 0;
}
.landing .row2_section_text{
    font-size: 20px;
    font-weight: 500;

}
.landing .row2_get_started_btn{
    width: 180px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #B20C02;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
}
.landing .main_row3{
    max-width: 100%;
    padding-top: 100px;
    background-color: #B20C02;
    height: 900px;
}
.landing .row3_section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 100px 0 100px;
    align-items: center;
}
.landing .row3_section_title{
    font-size: 64px;
    font-weight: 700;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
    margin-top: 0;
}
.landing .row3_section_msg{
    text-align: center;
    font-size: 21px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 100px;
}
.landing .row3_globe_wrapper{
    display: flex;
    justify-content: center;
}
.landing .main_row4{
    max-width: 100%;
    height: 400px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.landing .row4_section_title{
    font-size: 38px;
    font-weight: 700;
}
.landing .row4_section_container{
    display: flex;
    gap: 40px;
    align-items: center;
    text-align: center;
}
.landing .row4_section_item{
    width: 300px;
    padding: 10px;
}
.landing .row4_section_sub_title{
    font-size: 26px;
    font-weight: 600;
}
.landing .row4_section_msg{
    font-size: 16px;
    font-weight: 500;
}
.landing .main_row5{
    display: flex;
}
.landing .row5_img{
    width: 100%;
}
.landing .row5_section_container{
    background-color: #B20C02;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.landing .row5_section_container:nth-child(1){
    background-color: transparent;
}
.landing .row5_section_title{
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}
.landing .row5_section_msg{
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
}
.landing .row5_app_store_container{

}
.landing .main_row6{
    max-width: 100%;
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.landing .row6_section_title{
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-top: 0;
}
.landing .row6_section_container{
    display: flex;
   justify-content: center;
    gap: 20px;
}
.landing .section_review_comment{
    width: 30%
}
.landing .footer_container{
    max-width: 100%;
    background-color: #B20C02;
}
.landing .footer_info_container{
    display: flex;
    justify-content: space-between;
    padding: 50px 100px 50px 100px;
}
.landing ul{
    list-style-type: none;
}
.landing ul a{
    color: #FFFFFF;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}
.landing .footer_section2{
   display: flex;
   gap: 10px;
}
.landing .footer_icon_links{
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 0;
}
.landing .footer_section{

}
.landin .footer_sub_section{

}
.landing .landing_hr{
    border-bottom: 1px solid #FFFFFF;
}
.landing .footer_copyright_text{
    text-align: center;
    color: #FFFFFF;
    padding: 10px;
    margin: 0;
}
.poweredBy{
    display: flex;
    gap: 10px;
}
.poweredByTxt{
    color: #FFFFFF;
    font-weight: 500;
}
.poweredByImg{
    width: 40px;
    height: 40px;
}