
.paym_req_super_container1{
    padding: 20px;
}
.paym_req_super_container_blur{
    -webkit-filter: blur(10px);
    filter: blur(10px);
}
.paym_req_header_title{
    font-size: 22px;
    font-weight: 700;
    padding: 10px;
    margin-bottom: 0;
}
.paym_req_desc_text{
    margin-top: 2px;
    padding: 0 10px 0 10px;
    margin-bottom: 30px;
}
.paym_req_row2_container{
    display: flex;
    justify-content: space-between;
}
.paym_req__container{
    display: flex;
    justify-content: space-between;
}
.paym_req_btn_wrapper{
    display: flex;
    gap: 10px;
}
.paym_req_btn{
    background-color: #B20C02;
    color: #FFFFFF;
    height: 48px;
    border: none;
    border-radius: 10px;
    float: right;
    width: 200px;
}
.paym_req_search_bar_container{
    width: 50%;
    display: flex;
    gap: 10px;
    margin-bottom: 30px;
}
.paym_req_search_box{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 70%;
    height: 48px;
    border-radius: 10px;
    font-size: 16px;
    box-shadow: 0 0 5px #D0D5DD;
    border: 2px solid #D0D5DD;
    padding-left: 10px;

}

#paym_req_search_input{
    border: none;
    height: 100%;
    margin: 0;
    outline: none;
    scale: none;
    color: #667085;
    font-size: 16px;
}
.paym_req_search_filter{
    height: 53px;
    border-radius: 10px;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    box-shadow: 0 0 4px #D0D5DD;
    border: 2px solid #D0D5DD;
    color: #667085;
}
.paym_req_header{
    display: flex;
    flex: 1 1 100%;
    background-color: #FCFCFD;
    padding: 0 10px 0 10px;
}
.paym_req_header .paym_req_text{
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    width: 100%;
}
.paym_req_hr{
    height: 1px;
    background-color: #D9D9D9;
    border: none;
}
.paym_req_section{
    display: flex;
    flex: 1 1 100%;
    padding: 0 10px 0 10px;
    gap: 5px;
}
.paym_req_section:hover{
    background-color: #D9D9D9;
    scale: 1.005;
    transition: 1s;
}
.paym_req_section .paym_req_text_ln{
    width: 100%;
    font-size: 16px;
}
.paym_req_section .paym_req_status_complete{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.paym_req_section .paym_req_status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
}
.paym_req_status{
    color: #FF3636;
}   