.cust_section .cust_status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.cust_section .cust_status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
}