
.paym_splt_super_container{
    padding: 10px;
}
.paym_splt_super_container_blur{
    -webkit-filter: blur(10px);
    filter: blur(10px);
}
.paym_splt_header_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.paym_splt_header_title{
    font-size: 22px;
    font-weight: 700;
    margin: 0;
}
.paym_splt_desc_text{
    margin-top: 2px;
}
.paym_splt_create_btn{
    background-color: #B20C02;
    color: #FFFFFF;
    height: 48px;
    border: none;
    border-radius: 10px;
    width: 200px;
}
.paym_splt_header{
    display: flex;
    flex: 1 1 100%;
    padding: 5px 0;
    gap: 5px;
}
.paym_splt_details_container{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 0px;
}
.paym_splt_percentage{
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}
.paym_splt_edit_btn{
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 70px;
    background-color: transparent;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}
.paym_splt_edit_btn .edit_icon{
    padding-right: 3px;
}

.paym_splt_header .paym_splt_text{
    font-size: 16px;
    font-weight: 700;
    color: #9E9E9E;
    width: 100%;
}
.paym_splt_hr{
    height: 2px;
    background-color: #FCFCFD;
    border: none;
}
.paym_splt_section{
    display: flex;
    flex: 1 1 100%;
    padding: 5px 0;
    gap: 5px;
}
.paym_splt_section:hover{
    background-color: #FCFCFD;
}
.paym_splt_section .paym_splt_text_ln{
    width: 100%;
    font-size: 16px;
    width: 100%;
}
.paym_splt_section .paym_splt_action_div{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.paym_splt_section .paym_splt_status_successful{
    color: #00A520;
    background-color: #E0F4E8;
    border-radius: 5px;
    height: 20px;
    padding: 3px;
    max-width: 90px;
    text-align: center;

}
.paym_splt_section .paym_splt_status_failed{
    color: #FF0000;
    background-color: #F8E3E6;
    border-radius: 5px;
    padding: 3px;
    max-width: 90px;
    text-align: center;
}
.paym_splt_add_customer_container{
    position: absolute;
    top: 300px;
    left: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
